import {defineStore} from 'pinia';
import DetailedProperty from '@/properties/model/detailedProperty';
import {ImagesResponse} from '@/properties/model/imagesResponse';
import {pageService} from '@/properties/views/components/PropertyRegistration/stores/pageData';
import constants from '@/properties/views/components/PropertyRegistration/scripts/constants';
import {Wall} from "@/properties/model/wall";
import {Roof} from "@/properties/model/roof";
import {Window} from "@/properties/model/window";
import {CardinalDirection} from "@/properties/model/cardinalDirection";
import {Attic} from "@/properties/model/attic";
import {reactive} from "vue";
import { BuildingPart } from '@/properties/model/buildingPart';
import {RoofType} from "@/properties/model/roofType";

//Cache für Bilder, um doppelte Datenanfragen zu unterbinden
interface Images {
    plan: {
        floor: ImagesResponse[],                  //Bilder Grundriss - Binary
        floorThumbnails: string[],
        view: ImagesResponse[],                   //Bilder Anischt - Binary
        viewThumbnails: string[],
        crossSection: ImagesResponse | null,      //Bild Querschnitt - Binary
        crossSectionThumbnail: string,
        sitePlan: ImagesResponse | null           //Bild Lageplan - Binary
        sitePlanThumbnail: string,
    },
    pictures: {
        roofView: ImagesResponse | null,
        roofViewThumbnail: string,
        entranceView: ImagesResponse | null,
        entranceViewThumbnail: string,
        backView: ImagesResponse | null,
        backViewThumbnail: string,
        siteView1: ImagesResponse | null,
        siteView1Thumbnail: string,
        siteView2: ImagesResponse | null
        siteView2Thumbnail: string
    },
    windows: ImagesResponse[] | null,
    windowsThumbnails: string[]
}

export const imageService = defineStore('images', {
    state: (): {
        images: Images,
        newImages: string[],
    } => ({
        images: imageDefaultState,
        newImages: [],
    }),
    actions: {
        resetImages() {
            this.$state = JSON.parse(JSON.stringify(imageDefaultState));
        },
        addNewImage(imageKey: string) {
            this.newImages.push(imageKey);
        },
        resetAllNewImages() {
            this.newImages.length = 0;
        },
        removeNewImage(imageKey: string) {
            this.newImages = this.newImages.filter(key => key !== imageKey);
        },
        removeImages() {
            const buildingStore = buildingService();
            for (const imageKey of this.newImages) {
                // search for the corresponding image by imageKey
                // check floor images
                for (let i = 0; i < this.images.plan.floor.length; i++) {
                    if (this.images.plan.floor[i]?.imageKey.value === imageKey) {
                        const deletedElements = this.images.plan.floor.splice(i, 1);
                        if (deletedElements[0].imageKey.value === buildingStore.detailedProperty.basic?.displayImage) {
                            buildingStore.detailedProperty.basic.displayImage = '';
                        }
                        this.images.plan.floorThumbnails.splice(i, 1);
                    }
                }
                // check view images
                for (let i = 0; i < this.images.plan.view.length; i++) {
                    if (this.images.plan.view[i]?.imageKey.value === imageKey) {
                        const deletedElements = this.images.plan.view.splice(i, 1);
                        if (deletedElements[0].imageKey.value === buildingStore.detailedProperty.basic?.displayImage) {
                            buildingStore.detailedProperty.basic.displayImage = '';
                        }
                        this.images.plan.viewThumbnails.splice(i, 1);
                    }
                }
                // check cross section
                if (this.images.plan.crossSection && this.images.plan.crossSection.imageKey.value === imageKey) {
                    if (this.images.plan.crossSection.imageKey.value === buildingStore.detailedProperty.basic?.displayImage) {
                        buildingStore.detailedProperty.basic.displayImage = '';
                    }
                    this.images.plan.crossSection = {
                        image: {
                            value: ''
                        },
                        imageKey: {
                            value: ''
                        },
                        floorNumber: {
                            value: 0
                        }
                    };
                    this.images.plan.crossSectionThumbnail = '';
                }
                // check site plan
                if (this.images.plan.sitePlan && this.images.plan.sitePlan.imageKey.value === imageKey) {
                    this.images.plan.sitePlan = {
                        image: {
                            value: ''
                        },
                        imageKey: {
                            value: ''
                        },
                        floorNumber: {
                            value: 0
                        }
                    };
                    this.images.plan.sitePlanThumbnail = '';
                }
                // check roof view
                if (this.images.pictures.roofView && this.images.pictures.roofView.imageKey.value === imageKey) {
                    this.images.pictures.roofView = {
                        image: {
                            value: ''
                        },
                        imageKey: {
                            value: ''
                        },
                        floorNumber: {
                            value: 0
                        }
                    };
                    this.images.pictures.roofViewThumbnail = '';
                }
                // check entrance view
                if (this.images.pictures.entranceView && this.images.pictures.entranceView.imageKey.value === imageKey) {
                    this.images.pictures.entranceView = {
                        image: {
                            value: ''
                        },
                        imageKey: {
                            value: ''
                        },
                        floorNumber: {
                            value: 0
                        }
                    };
                    this.images.pictures.entranceViewThumbnail = '';
                }
                // check back view
                if (this.images.pictures.backView && this.images.pictures.backView.imageKey.value === imageKey) {
                    this.images.pictures.backView = {
                        image: {
                            value: ''
                        },
                        imageKey: {
                            value: ''
                        },
                        floorNumber: {
                            value: 0
                        }
                    };
                    this.images.pictures.backViewThumbnail = '';
                }
                // check site view 1
                if (this.images.pictures.siteView1 && this.images.pictures.siteView1.imageKey.value === imageKey) {
                    this.images.pictures.siteView1 = {
                        image: {
                            value: ''
                        },
                        imageKey: {
                            value: ''
                        },
                        floorNumber: {
                            value: 0
                        }
                    };
                    this.images.pictures.siteView1Thumbnail = '';
                }
                // check site view 2
                if (this.images.pictures.siteView2 && this.images.pictures.siteView2.imageKey.value === imageKey) {
                    this.images.pictures.siteView2 = {
                        image: {
                            value: ''
                        },
                        imageKey: {
                            value: ''
                        },
                        floorNumber: {
                            value: 0
                        }
                    };
                    this.images.pictures.siteView2Thumbnail = '';
                }
            }
        }
    },
    getters: {
        amountOfImagesStored(): number {
            return this.images.plan.floor.length +
                this.images.plan.view.length +
                (this.images.plan.crossSection ? 1 : 0) +
                (this.images.plan.sitePlan ? 1 : 0) +
                (this.images.pictures.roofView ? 1 : 0) +
                (this.images.pictures.entranceView ? 1 : 0) +
                (this.images.pictures.backView ? 1 : 0) +
                (this.images.pictures.siteView1 ? 1 : 0) +
                (this.images.pictures.siteView2 ? 1 : 0);
        }
    }
});

// Default wird hier initialisiert und zum Zurücksetzen der Werte genutzt
const imageDefaultState = {
    plan: {
        floor: [],
        floorThumbnails: [],
        view: [],
        viewThumbnails: [],
        crossSection: null,
        crossSectionThumbnail: "",
        sitePlan: null,
        sitePlanThumbnail: ""
    },
    pictures: {
        roofView: null,
        roofViewThumbnail: "",
        entranceView: null,
        entranceViewThumbnail: "",
        backView: null,
        backViewThumbnail: "",
        siteView1: null,
        siteView1Thumbnail: "",
        siteView2: null,
        siteView2Thumbnail: ""
    },
    windows: [],
    windowsThumbnails: []
}

let changed = false;
let watchTriggerBuffer = 0;


export const buildingService = defineStore('building', {
    state: (): {
        detailedProperty: DetailedProperty,
        buildingPartEditingIsActive: boolean,
        buildingPartSavingTrigger: number
    } => ({
        detailedProperty: buildingDefaultState,
        buildingPartEditingIsActive: false,
        buildingPartSavingTrigger: 0
    }),
    actions: {
        loadProperty(property: DetailedProperty) {
            const page = pageService();
            this.resetProperty();
            page.reset();

            this.$state.detailedProperty.basic = property.basic;
            this.$state.detailedProperty.wallWrapper = property.wallWrapper;
            this.$state.detailedProperty.floor = property.floor;
            this.$state.detailedProperty.atticWrapper = property.atticWrapper;
            this.$state.detailedProperty.windowWrapper = property.windowWrapper;
            this.$state.detailedProperty.roofWrapper = property.roofWrapper;
            this.$state.detailedProperty.technology = property.technology;

            // Erhaltene Property nach Kategorie übernehmen, um Fehler mit Null-Referenzen im Control-Binding zu verhindern
            // this.deepCopyCategory(constants.AllBasics, property.basic, this.$state.basic);
            // this.deepCopyCategory(constants.AllWalls, property.wallWrapper, this.$state.wallWrapper);
            // this.deepCopyCategory(constants.AllFloor, property.floor, this.$state.floor);
            // this.deepCopyCategory(constants.AllWindows, property.window, this.$state.window);
            // this.deepCopyCategory(constants.AllRoof, property.roof, this.$state.roof);
            // this.deepCopyCategory(constants.AllTechnology, property.technology, this.$state.technology);

            if (this.$state.detailedProperty.basic) {
                if (this.$state.detailedProperty.basic.origin) {
                    if (this.$state.detailedProperty.basic.origin != '') {
                        page.changeShowState(constants.Intro, false);
                        page.changeShowState(constants.DetailedResults, false);

                        // if a category is null, don't display it
                        // if no building parts are recorded in a shared category, no need to display it
                        page.changeShowState(constants.AllWalls, !!property.wallWrapper && property.wallWrapper.walls.length > 0);
                        page.changeShowState(constants.AllFloor, property.floor !== null);
                        const anyWindowsPresent = !!property.windowWrapper &&
                            (property.windowWrapper.windows.length > 0);
                        page.changeShowState(constants.AllWindows, !!property.windowWrapper && anyWindowsPresent);
                        page.changeShowState(constants.AllRoof, !!property.roofWrapper && property.roofWrapper.roofParts.length > 0);
                        page.changeShowState(constants.AllTechnology, property.technology !== null);

                        // for now: do not allow editing on properties that do not belong to the user
                        page.changeEntireEditableState(false);
                    }
                }
            }
            changed = false;
            return true;
        },
        deepCopyCategory(categoryIndex: number, sourceCategory: any, destCategory: any, recursiveCall?: boolean) {
            const page = pageService();
            //Scan object
            if (sourceCategory === null) {
                if (typeof (recursiveCall) === 'undefined') {
                    page.changeShowState(categoryIndex, false);
                }
                return
            }

            Object.keys(sourceCategory).forEach(key => {
                if (typeof (sourceCategory[key]) === 'object') {
                    // Array-Elemente für Window-Details müssen im Vorfeld definitert werden, da sonst die Elemente undefiniert wären
                    if ((categoryIndex === constants.AllWindows || categoryIndex === constants.AllTechnology) && typeof (destCategory[key]) === 'undefined') {
                        destCategory[key] = Object.create(sourceCategory[key]);
                    }

                    this.deepCopyCategory(categoryIndex, sourceCategory[key], destCategory[key], true);
                } else {
                    if (sourceCategory[key] !== null) {
                        destCategory[key] = sourceCategory[key];

                        if (destCategory[key] === 0) {
                            destCategory[key] = null;
                        }
                    }
                }
            });
        },
        resetProperty() {
            this.$state.detailedProperty.basic = {
                propertyKey: null,
                userKey: null,
                description: '',          //Bezeichnung
                type: 0,                  //Enum - 1 Freistehend, 2 Einseitig angebaut, 4 Mehrseitig angebaut
                flatCount: null,
                rooms: null,
                fullArea: null,
                annualConsumptionWarmth: null,
                annualConsumptionElectricity: null,
                buildYear: 0,             //Enum - 1 bis 1918, 2 1919-1948, 3 1949-1957, 4 1958-1968, 5 1969-1978, 6 1979-1983, 7 1984-1994, 8 1995-2004, 9 ab 2005
                basicAddress: {
                    street: '',
                    houseNumber: null,
                    zip: null,
                    city: '',
                    state: 0                //Enum - Bundesländer
                },
                energyClass: '',
                isDeleted: false,
                origin: '',
                displayImage: ''
            }

            this.$state.detailedProperty.wallWrapper = {
                wallAmount: 0,
                walls: []
            }

            this.$state.detailedProperty.floor = {
                cellarIsPresent: false,
                cellarHeight: 0,
                floorWidth: 0,
                floorLength: 0,
                floorArea: 0,
                isHeated: false,
                floorThickness: 0,
                floorConstructionType: 0,
                floorInsulationType: 0,
                floorInsulationThickness: 0,
                floorInsulationThermalConductivity: 0,
            }

            this.$state.detailedProperty.atticWrapper = {
                atticAmount: 0,
                attics: []
            }

            this.$state.detailedProperty.windowWrapper = {
                windowAmount: 0,
                windows: []
            }

            this.$state.detailedProperty.roofWrapper = {
                roofAmount: 0,
                roofParts: []
            }

            this.$state.detailedProperty.technology = {
                heating: {
                    buildYear: 0,
                    heatingMainCategory: 0,
                    centralHeatGenerator: 0,
                    flatCentralHeatGenerator: 0,
                    singleHeatGenerator: 0,
                    heatPumpType: 0,
                    temperature: 0,
                    source: 0,
                    heatTransfers: [],
                    heatingStoragePresent: false,
                    storageVolume: 0
                },
                water: {
                    buildYear: 0,
                    warmWaterGenerator: 0,
                    heatPumpType: 0,
                    source: 0,
                    storage: 0,
                    storageVolume: 0,
                    distributionType: 0
                },
                ventilation: {
                    type: 0,
                    buildYear: 0,
                    installationPlace: 0
                },
                photovoltaic: {
                    storage: 0,
                }
            }
        },
        setChanged(newState: boolean) {
            changed = newState;
        },
        isChanged() {
            return changed;
        },
        setBuildingPartEditingIsActive(newState: boolean) {
            this.$state.buildingPartEditingIsActive = newState;
        },
        isBuildingPartEditingActive(): boolean {
            return this.$state.buildingPartEditingIsActive;
        },
        // triggered when user is starting to edit a building part (wall, roofPart, floor)
        // or is creating a new one and set the cardinal direction
        // cardinalDirection will be '-' when roofType is "Flachdach"
        setInformationOfCurrentlyEditedBuildingPart(buildingPart: BuildingPart, cardinalDirection: CardinalDirection = CardinalDirection.NORTH, roofType: RoofType = RoofType.SATTELDACH) {
            console.log('setInformationOfCurrentlyEditedBuildingPart');
        },
        activateBuildingPartSavingTrigger() {
            this.$state.buildingPartSavingTrigger++;
        },
        getWatchTriggerBuffer(): number {
            return watchTriggerBuffer;
        },
        incrementWatchTriggerBuffer() {
            watchTriggerBuffer++;
        },
        decrementWatchTriggerBuffer() {
            if (watchTriggerBuffer > 0) {
                watchTriggerBuffer--;
            }
        },
        isFilled() {
            // Revisit: Mindestangaben zum Speichern definieren
            if (this.$state.detailedProperty.basic) {
                if (this.$state.detailedProperty.basic.description !== '') {
                    return true;
                }
            }
        }
    },
    getters: {
        getWalls(): Wall[] | null {
            if (!this.detailedProperty.wallWrapper) {
                return null;
            }
            return this.detailedProperty.wallWrapper.walls;
        },
        getRoofParts(): Roof[] | null {
            if (!this.detailedProperty.roofWrapper) {
                return null;
            }
            return this.detailedProperty.roofWrapper.roofParts;
        },
        getAttics(): Attic[] | null {
            if (!this.detailedProperty.atticWrapper) {
                return null;
            }
            return this.detailedProperty.atticWrapper.attics;
        },
        getWindows(): Window[] | null {
            if (!this.detailedProperty.windowWrapper) {
                return null;
            }
            return this.detailedProperty.windowWrapper.windows;
        }
    }
});

// Default wird hier initialisiert und zum Zurücksetzen der Werte genutzt
const buildingDefaultState = reactive({
    basic: {
        propertyKey: null,
        userKey: null,
        description: '',          //Bezeichnung
        type: 0,                  //Enum - 1 Freistehend, 2 Einseitig angebaut, 4 Mehrseitig angebaut
        flatCount: null,
        rooms: null,
        fullArea: null,
        annualConsumptionWarmth: null,
        annualConsumptionElectricity: null,
        buildYear: 0,             //Enum - 1 bis 1918, 2 1919-1948, 3 1949-1957, 4 1958-1968, 5 1969-1978, 6 1979-1983, 7 1984-1994, 8 1995-2004, 9 ab 2005
        basicAddress: {
            street: '',
            houseNumber: null,
            zip: null,
            city: '',
            state: 0                //Enum - Bundesländer
        },
        energyClass: '',
        isDeleted: false,
        origin: '',
        displayImage: ''
    },
    wallWrapper: {
        wallAmount: 0,
        walls: []
    },
    floor: {
        cellarIsPresent: false,
        cellarHeight: 0,
        floorWidth: 0,
        floorLength: 0,
        floorArea: 0,
        isHeated: false,
        floorThickness: 0,
        floorConstructionType: 0,
        floorInsulationType: 0,
        floorInsulationThickness: 0,
        floorInsulationThermalConductivity: 0,
    },
    atticWrapper: {
        atticAmount: 0,
        attics: []
    },
    windowWrapper: {
        windowAmount: 0,
        windows: []
    },
    roofWrapper: {
        roofAmount: 0,
        roofParts: []
    },
    technology: {
        heating: {
            buildYear: 0,
            heatingMainCategory: 0,
            centralHeatGenerator: 0,
            flatCentralHeatGenerator: 0,
            singleHeatGenerator: 0,
            heatPumpType: 0,
            temperature: 0,
            source: 0,
            heatTransfers: [],
            heatingStoragePresent: false,
            storageVolume: 0
        },
        water: {
            buildYear: 0,
            warmWaterGenerator: 0,
            heatPumpType: 0,
            source: 0,
            storage: 0,
            storageVolume: 0,
            distributionType: 0
        },
        ventilation: {
            type: 0,
            buildYear: 0,
            installationPlace: 0,
        },
        photovoltaic: {
            storage: 0,
        }
    }
});