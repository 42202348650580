import DetailedProperty from "@/properties/model/detailedProperty";

export function resetScrollPosition() {
    const mainElement = document.getElementById("mainOutlet");
    if (!mainElement) {
        return;
    }
    mainElement.scrollTo(0, 0);
}

export async function constructFillerPropertyName(properties: DetailedProperty[] | null): Promise<string> {
    if (!properties) {
        return 'Neue Immobilie 1';
    }
    const regex = /Neue Immobilie (\d+)/;

    // find properties with name "Neue Immobilie X"
    const usedNumbers = properties
        .map(property => {
            if (!property.basic) {
                return false;
            }
            const match = property.basic.description.match(regex);
            return match ? parseInt(match[1], 10) : null;
        })
        .filter((num): num is number => num !== null)
        .sort((a, b) => a - b);

    // find smallest number
    let expectedNumber = 1;
    for (const num of usedNumbers) {
        if (num !== expectedNumber) {
            break;
        }
        expectedNumber++;
    }
    return 'Neue Immobilie ' + expectedNumber;
}

export function resolveImgURL(fileName: string) {
    if (fileName != undefined) {
        const images = require.context('@/properties/views/components/PropertyRegistration/assets/icons/', false, /\.svg$/);
        return images("./" + fileName);
    }
}

export function convertFloatToLocale(floatToConvert: number) {
    return Intl.NumberFormat('de-DE').format(parseFloat(floatToConvert.toFixed(2)));
}
